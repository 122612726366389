import { sFetchJson } from "../../../../../../common/util/sFetchJson";
import { GrammarFromServerSide } from "../../../../zApps/Grammar/types";
import { CompareFunctions } from "./types";

export const getGrammarFnc = async () => {
    const allGrammar = await sFetchAllGrammar();
    return getFunctionsFromAllGrammar(allGrammar);
};

export async function sFetchAllGrammar(): Promise<GrammarFromServerSide[]> {
    return sFetchJson<GrammarFromServerSide[]>("api/Grammar/GetAllGrammar");
}

export function getFunctionsFromAllGrammar(
    allGrammar: GrammarFromServerSide[]
) {
    return allGrammar.reduce((acc, grammar) => {
        return {
            ...acc,
            [getGrammarSaveKey(grammar.path)]: (
                db: string | null,
                storage: string | null
            ) => {
                const storageScore = Number(storage) || 0;
                const dbScore = Number(db) || 0;
                if (storageScore > dbScore) {
                    return "storage";
                }
                return "db";
            },
        };
    }, {} as CompareFunctions);
}

export function getGrammarSaveKey(path: string) {
    return `GrammarQuiz-score-${path}`;
}
